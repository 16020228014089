import React from "react";
import { Helmet } from "react-helmet";

import { PageLayout } from "../../layouts";
import { Block } from '../../components';

import "../../global/styles/page-styles/giveaways.scss";

const RazerGoldGiveawayPage = () => {
    return (
        <>
            <Helmet>
                <title>Partie | Giveaway</title>
                <script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true" />
            </Helmet>
            <PageLayout className="giveaways-page">
                <Block className="giveaways-block-content" hero lazy>
                    <h2 className="partie-block__title">Partie + Razer PC <br/> Full Gaming Setup Giveaway</h2>
                    <a class="e-widget no-button generic-loader" href="https://gleam.io/MCpD7/partie-razer-pc-full-gaming-setup-giveaway" rel="nofollow">Partie | Razer PC + Full Gaming Setup Giveaway</a>
                </Block>
            </PageLayout>
        </>
    );
};

export default RazerGoldGiveawayPage;